// src/App.js
import React, { Suspense, lazy } from 'react'
import { Routes, Route } from 'react-router-dom'
import Layout from './Components/Layout'
import { UserProvider } from './UserContext' // Importa UserProvider
import { withAuth } from './hocs/withAuth'
import AlertIdPage from './Pages/alerts/alert-id'

const Login = lazy(() => import('./Pages/Login/Login'))
const Home = lazy(() => import('./Pages/Home/Home'))
const UsersManagement = lazy(() => import('./Pages/UsersManagement'))
const Apps = lazy(() => import('./Pages/AppsManagement'))
const Roles = lazy(() => import('./Pages/RolesManagement'))
const UsersRoles = lazy(() => import('./Pages/UsersRolesManagement'))
const Functionalities = lazy(() => import('./Pages/FunctionalitiesManagement'))
const DefaultSettings = lazy(() => import('./Pages/DefaultSettingsManagement'))
const CustomSettings = lazy(() => import('./Pages/CustomSettingsManagement'))
const Verifications = lazy(() => import('./Pages/VerificationsManagement'))
const Profiles = lazy(() => import('./Pages/ProfilesManagement'))
const Rules = lazy(() => import('./Pages/RulesManagement'))
const Alerts = lazy(() => import('./Pages/alerts'))
const Scopes = lazy(() => import('./Pages/ScopesManagement'))
const Checks = lazy(() => import('./Pages/ChecksManagement'))
const TransactionDetails = lazy(() => import('./Pages/transactions/details'))
const Types = lazy(() => import('./Pages/TypesManagement'))
const SubTypes = lazy(() => import('./Pages/SubtypesManagement'))
const RedLink = lazy(() => import('./Pages/RedLinkManagement'))

function App() {
  const LayoutWithAuth = withAuth(Layout)
  const HomeWithAuth = withAuth(Home)
  const UsersManagementWithAuth = withAuth(UsersManagement)
  const AppsWithAuth = withAuth(Apps)
  const RolesWithAuth = withAuth(Roles)
  const UsersRolesWithAuth = withAuth(UsersRoles)
  const FunctionalitiesWithAuth = withAuth(Functionalities)
  const DefaultSettingsWithAuth = withAuth(DefaultSettings)
  const CustomSettingsWithAuth = withAuth(CustomSettings)
  const VerificationsWithAuth = withAuth(Verifications)
  const ProfilesWithAuth = withAuth(Profiles)
  const AlertsWithAuth = withAuth(Alerts)
  const RulesWithAuth = withAuth(Rules)
  const ScopesWithAuth = withAuth(Scopes)
  const ChecksManagementWithAuth = withAuth(Checks)
  const TypesManagementWithAuth = withAuth(Types)
  const SubtypesManagementWithAuth = withAuth(SubTypes)
  const TransactionDetailsWithAuth = withAuth(TransactionDetails)
  const ReadLinkWithAuth = withAuth(RedLink)

  // Estilo en línea para aplicar la fuente a toda la app
  const appStyle = {
    fontFamily: 'Nunito, sans-serif',
  }

  // Estilo para aplicar color solo a la primera fila (encabezado) y a las demás filas
  const tableRowStyle = `
    th {
      background-color: #66b4d129; /* Color de fondo para el encabezado de la tabla */
  
      }

input:disabled {
  color: black !important;    /* Cambia el color de la fuente a negro */

}




  `

  return (
    <UserProvider>
      <div className="App" style={appStyle}>
        <style>{tableRowStyle}</style>
        <Suspense
          fallback={
            <div>
              <h1>Loading...</h1>
            </div>
          }
        >
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route element={<LayoutWithAuth />}>
              <Route path="/" element={<HomeWithAuth />} />
              <Route path="/users/new" element={<UsersManagementWithAuth />} />
              <Route path="/users/:userId" element={<UsersManagementWithAuth />} />
              <Route path="/users" element={<UsersManagementWithAuth />} />
              <Route path="/settings/apps" element={<AppsWithAuth />} />
              <Route path="/settings/roles" element={<RolesWithAuth />} />
              <Route path="/users_roles" element={<UsersRolesWithAuth />} />
              <Route path="/settings/functionalities" element={<FunctionalitiesWithAuth />} />
              <Route path="/feeses/defaultsettings" element={<DefaultSettingsWithAuth />} />
              <Route path="/feeses/customsettings" element={<CustomSettingsWithAuth />} />
              <Route path="/verifications" element={<VerificationsWithAuth />} />
              <Route path="/users/:userId/compliance" element={<UsersManagementWithAuth />} />
              <Route path="/settings/profiles" element={<ProfilesWithAuth />} />
              <Route path="/settings/rules" element={<RulesWithAuth />} />
              <Route path="/settings/alerts" element={<AlertsWithAuth />} />
              <Route path="/settings/alerts/:id" element={<AlertIdPage />} />
              <Route path="/settings/scopes" element={<ScopesWithAuth />} />
              <Route path="/settings/types" element={<TypesManagementWithAuth />} />
              <Route path="/settings/subtypes" element={<SubtypesManagementWithAuth />} />
              <Route path="/checks" element={<ChecksManagementWithAuth />} />
              <Route path="/transactions/:id" element={<TransactionDetailsWithAuth />} />
              <Route path="/accounting/reconcile/redlink" element={<ReadLinkWithAuth />} />
            </Route>
          </Routes>
        </Suspense>
      </div>
    </UserProvider>
  )
}

export default App
