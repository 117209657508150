import React from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from './Sidebar/Sidebar'

const Layout = ({ user }) => {
  // console.log('Rendering Layout with user:', user); // Depuración

  return (
    <div>
      {user && <Sidebar user={user} />}
      <Outlet />
    </div>
  )
}

export default Layout
