import { Box, Modal, Stack, Typography, IconButton, Grid, Divider } from '@mui/material'
import { Add } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { getCvuDetails, getTransactions } from '../../Services/api'
import { useQuery } from '@tanstack/react-query'
import { format } from 'date-fns'
import { formatToDecimal } from '../../utils/money-format'

const LabelValueRow = ({ label, value }) => (
  <Grid container spacing={1} alignItems="center" sx={{ my: 0.5 }}>
    <Grid item xs={4}>
      <Typography variant="body1">{label}</Typography>
    </Grid>
    <Grid item xs={8}>
      <Typography variant="body1" fontWeight="bold" textAlign="right">
        {value}
      </Typography>
    </Grid>
  </Grid>
)

const ModalTrxDetails = ({ open = false, onClose = () => {}, trxId }) => {
  const navigate = useNavigate()

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    minWidth: '400px',
  }

  const { data, isLoading, isError } = useQuery({
    queryKey: ['transactions', trxId],
    queryFn: () => getTransactions(trxId),
  })
  const idCvu = data?.data?.[0].cvu_id
  const {
    data: cvuData,
    isLoading: isCvuLoading,
    isError: isCvuError,
  } = useQuery({
    queryKey: ['user', idCvu],
    queryFn: () => getCvuDetails(idCvu),
    enabled: !!idCvu,
  })
  if (isLoading || isCvuLoading) return <Typography variant="body1">Loading...</Typography>

  if (isError || isCvuError)
    return (
      <Typography variant="body1" color={'error'}>
        There was an error retrieving the data
      </Typography>
    )

  const {
    created_at,
    cvu_id,
    description,
    id,
    amount,
    osAccount,
    osOwner,
    osTaxId,
    type,
    user_id,
  } = data?.data?.[0]

  const { email, cvu, alias } = cvuData.data

  const goNavigateTo = () => {
    navigate(`/transactions/${trxId}`)
    onClose()
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h2" component="h2" fontWeight="bold">
            CVU - Transacción
          </Typography>
          <IconButton onClick={goNavigateTo}>
            <Add sx={{ color: 'primary.main' }} />
          </IconButton>
        </Stack>

        <Divider sx={{ my: 2 }} />

        <LabelValueRow label="ID" value={id} />
        <LabelValueRow label="Amount" value={`$${formatToDecimal(amount.replace(/[+-]/g, ''))}`} />
        <LabelValueRow label="Date" value={format(new Date(created_at), 'dd/MM/yyyy HH:mm')} />

        <Divider sx={{ my: 2 }} />

        <Typography variant="h2" fontWeight="bold" component="h2" gutterBottom>
          Cvu
        </Typography>
        <LabelValueRow label="ID" value={cvu_id} />
        <LabelValueRow label="CVU" value={cvu} />
        <LabelValueRow label="Alias" value={alias} />
        <LabelValueRow label="User" value={user_id} />
        <LabelValueRow label="Bank" value={'Columbia'} />

        <Divider sx={{ my: 2 }} />

        <Typography variant="h2" fontWeight="bold" component="h2" gutterBottom>
          From
        </Typography>
        <LabelValueRow label="Owner" value={osOwner} />
        <LabelValueRow label="Account" value={osAccount} />
        <LabelValueRow label="TaxId" value={osTaxId} />

        <Divider sx={{ my: 2 }} />

        <LabelValueRow label="Type" value={type} />
        <LabelValueRow label="Applies to:" value="----" />
        <LabelValueRow label="Note:" value={description} />
      </Box>
    </Modal>
  )
}

export default ModalTrxDetails
