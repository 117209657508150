import React, { createContext, useContext, useState, useEffect } from 'react'
import axios from 'axios'

const UserContext = createContext()

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)

  const fetchUser = async () => {
    try {
      const response = await axios.get('/user', { withCredentials: true })
      setUser(response.data)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('PASO POR ACA')
        setUser(null)
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchUser()
  }, [])

  return (
    <UserContext.Provider value={{ user, loading, fetchUser }}>{children}</UserContext.Provider>
  )
}

export const useUser = () => {
  return useContext(UserContext)
}
