// src/index.js
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
import test_es from './translations/es/login.json'
import test_en from './translations/en/login.json'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// Inicialización de i18next
i18next.init({
  interpolation: { escapeValue: false },
  lng: 'es',
  resources: {
    es: {
      global: test_es,
      test: test_es,
    },
    en: {
      global: test_en,
      test: test_en,
    },
  },
})

// Añadir la fuente Nunito directamente
const link = document.createElement('link')
link.href = 'https://fonts.googleapis.com/css2?family=Nunito:wght@400;600&display=swap'
link.rel = 'stylesheet'
document.head.appendChild(link)

// Añadir estilos globales con !important
const style = document.createElement('style')
style.innerHTML = `
  /* Aplica la fuente Nunito solo a botones y encabezados */
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Nunito', sans-serif !important;
  }

input:disabled {
  color: black !important; /* Cambia el color de la fuente a negro */
}


  /* Tamaños específicos para los encabezados */
  h1 {
    font-size: 22px !important; /* tamaño para h1 */
  }
  h2 {
    font-size: 16px !important; /* tamaño para h2 */
  }
  h3 {
    font-size: 12px !important; /* tamaño para h3 */
  }
  h4 {
    font-size: 12px !important; /* tamaño para h4 */
  }
  h5 {
    font-size: 10px !important; /* tamaño para h5 */
  }
  h6 {
    font-size: 8px !important; /* tamaño para h6, si lo necesitas */
  }
.css-1qsxih2 {
    max-width: 100% !important;
}
  @media (min-width: 1200px) {
    .css-1oqqzyl-MuiContainer-root {
      max-width: 100% !important; /* Anula el max-width */
    }
  }
`
document.head.appendChild(style)

// Renderización de la aplicación
const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </QueryClientProvider>
    </I18nextProvider>
    <ToastContainer position="top-right" />
  </React.StrictMode>
)
