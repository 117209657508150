import { Box, Container, Typography, Tabs, Tab, Button } from '@mui/material'
import PropTypes from 'prop-types'
import { useState } from 'react'
import UserInfo from '../../../Components/Alerts/tabs-alert-details/info-tab'
import TransactionsTable from '../../../Components/Alerts/tabs-alert-details/transactions-tab'
import { useNavigate } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tab-panel"
      hidden={value !== index}
      id={`tab-panel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box width={'auto'} sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const AlertIdPage = () => {
  const [tabValue, setTabValue] = useState(0)

  const handleChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const navigate = useNavigate()

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
      }}
    >
      <Box sx={{ width: '256px', flexShrink: 0 }} />
      <Container>
        <Box py={3}>
          <Typography variant="h4" component="h1" gutterBottom>
            Alert Management
          </Typography>
        </Box>
        <Box sx={{ width: '100%', backgroundColor: '#FFF', borderRadius: '10px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: 1,
              borderColor: 'divider',
            }}
          >
            <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Info" {...a11yProps(0)} />
              <Tab label="Transactions" {...a11yProps(1)} />
              <Tab label="More info" {...a11yProps(2)} />
            </Tabs>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => navigate('/settings/alerts')}
              sx={{
                ml: 'auto',
                mr: 2,
                paddingRight: 2,
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <ArrowBack />
              Back to alerts
            </Button>
          </Box>
          <CustomTabPanel value={tabValue} index={0}>
            <UserInfo />
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            <TransactionsTable />
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={2}>
            Item Three
          </CustomTabPanel>
        </Box>
      </Container>
    </Box>
  )
}

export default AlertIdPage

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}
