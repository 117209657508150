import { Box, IconButton, Stack, Typography, Tooltip } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { format, parseISO } from 'date-fns'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import DescriptionIcon from '@mui/icons-material/Description'
import NorthEastIcon from '@mui/icons-material/NorthEast'
import ModalTrxDetails from '../../Transactions/modal-trx-details'
import { getTransactionsByAlert } from '../../../Services/api'
import { formatToDecimal } from '../../../utils/money-format'

const TransactionsTable = () => {
  const { id: alert_id_param } = useParams()
  const [openModal, setOpenModal] = useState(false)
  const [trxIdModal, setTrxIdModal] = useState(null)
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  })
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({})

  const { data, isLoading } = useQuery({
    queryKey: ['transaction-by-alert', alert_id_param, paginationModel.pageSize],
    queryFn: () =>
      getTransactionsByAlert({ id: alert_id_param, perPage: paginationModel.pageSize }),
  })

  console.log({ data })
  const dataTransactions = data?.data || []

  const handleCloseModal = () => {
    setOpenModal(false)
    setTrxIdModal(null)
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'type', headerName: 'Type', width: 100 },
    { field: 'currency', headerName: 'Currency', width: 100 },
    {
      field: 'amount',
      headerName: 'Amount',
      type: 'number',
      width: 120,
      valueFormatter: params => `$${formatToDecimal(params.replace(/[+-]/g, ''))}`,
    },
    { field: 'osAccount', headerName: 'Account', width: 120 },
    { field: 'osOwner', headerName: 'Owner', width: 130 },
    { field: 'osTaxId', headerName: 'Tax ID', width: 120 },
    { field: 'status', headerName: 'Status', width: 100 },
    {
      field: 'description',
      headerName: 'Description',
      width: 90,
      renderCell: params =>
        params.value ? (
          <Tooltip title={params.value}>
            <IconButton size="small">
              <DescriptionIcon />
            </IconButton>
          </Tooltip>
        ) : null,
      sortable: false,
      filterable: false,
    },
    { field: 'gw_trxId', headerName: 'Coelsa ID', width: 220 },
    {
      field: 'created_at',
      headerName: 'CreatedAt',
      width: 180,
      valueFormatter: params => format(parseISO(params), 'dd/MM/yyyy HH:mm:ss'),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      renderCell: params => (
        <IconButton
          size="small"
          onClick={() => {
            setOpenModal(true)
            setTrxIdModal(params.row.id)
          }}
        >
          <NorthEastIcon />
        </IconButton>
      ),
      sortable: false,
      filterable: false,
    },
  ]

  return (
    <Box sx={{ width: '100%' }}>
      <Box py={2} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
        <Stack direction="row" spacing={2}>
          <Typography variant="body1">ID:</Typography>
          <Typography variant="body1" fontWeight="bold">
            {alert_id_param}
          </Typography>
        </Stack>
      </Box>
      <Box sx={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={dataTransactions}
          columns={columns}
          loading={isLoading}
          getRowId={row => row.id}
          pageSizeOptions={[50, 100, 200]}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={setColumnVisibilityModel}
          disableRowSelectionOnClick
          localeText={{
            noRowsLabel: 'No transactions found.',
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                description: true,
                actions: true,
              },
            },
          }}
        />
      </Box>
      {openModal && (
        <ModalTrxDetails open={openModal} onClose={handleCloseModal} trxId={trxIdModal} />
      )}
    </Box>
  )
}

export default TransactionsTable
