import axios from 'axios'

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.REACT_APP_URL_API
const API_URL = process.env.REACT_APP_URL_API
const API_URL_COMPLIANCE = process.env.REACT_APP_URL_COMPLIANCE
const API_KEY = process.env.REACT_APP_API_KEY
const API_SECRET = process.env.REACT_APP_SECRET
const API_IQQA = process.env.REACT_APP_URL_API_IQQA

export const matchConciliation = async ({ trxId, externalId }) => {
  try {
    const response = await axios.post(
      `${API_IQQA}cvu/conciliation/match`,
      { trxId, externalId },
      {
        headers: {
          apiKey: API_KEY,
          secret: API_SECRET,
          'Content-Type': 'application/json',
        },
      }
    )
    return response
  } catch (err) {
    return []
  }
}

export const unMatchConciliation = async id => {
  try {
    const response = await axios.delete(`${API_IQQA}cvu/conciliation/unmatch/${id}`, {
      headers: {
        apiKey: API_KEY,
        secret: API_SECRET,
      },
    })
    return response
  } catch (err) {
    return []
  }
}

export const getConciliations = async ({
  dateFrom,
  dateTo,
  trxType,
  matched,
  taxId,
  page,
  perPage,
}) => {
  try {
    const response = await axios.get(`${API_IQQA}conciliation-view`, {
      headers: {
        'Content-Type': 'application/json',
        apiKey: '$2y$10$zPXuZr6hMtXdpC9HbgvoK.lx1ywcqa2IfVbaL.bXacxorAsLRlz8y',
        secret: '$2y$10$q.bfuAeyaRybb/m2Up/c/OlQRKWHdZHCFHHvUUIf5Duifc.P.bNgy',
        dateFrom,
        trxType,
        dateTo,
        matched,
        taxId,
        page,
        perPage,
      },
    })
    return response
  } catch (error) {
    return []
  }
}
export const getCvuDetails = async id => {
  const response = await axios.get(`${API_IQQA}cvu/${id}`, {
    headers: {
      apiKey: API_KEY,
      secret: API_SECRET,
    },
  })
  return response.data || []
}

export const getAlertById = async id => {
  const response = await axios.get(`${API_URL_COMPLIANCE}compliance/alerts/${id}`, {
    headers: {
      Accept: 'application/json',
    },
  })
  return response.data || []
}

export const getTransactionsByAlert = async ({ id, perPage }) => {
  const response = await axios.get(`${API_URL_COMPLIANCE}compliance/alerts/${id}/transactions`, {
    headers: {
      perPage,
    },
  })
  return response.data || []
}

export const getTransactions = async trxId => {
  const response = await axios.get(`${API_IQQA}cvu/transactions/`, {
    headers: {
      apiKey: API_KEY,
      secret: API_SECRET,
      trxId,
    },
  })
  return response.data || []
}

export const getAlertsByType = async ({ type = 'alert', page = 1, perPage = 50, status }) => {
  const response = await axios.get(`${API_URL_COMPLIANCE}compliance/alerts?page=${page}`, {
    headers: {
      type: 'risk',
      status,
      Accept: 'application/json',
      perPage,
    },
  })
  return response.data || []
}

export const getAlertNotesById = async id => {
  try {
    const elementType = 'COMPALERT'
    const response = await axios.get(`${API_URL}note/${id}`, {
      params: { elementType },
    })
    return response.data || []
  } catch (error) {
    console.error('Error en getAlertNotesById:', error)
    return { status: false, data: [] }
  }
}

export const createAlertNote = async data => {
  try {
    const noteData = {
      ...data,
      elementType: 'COMPALERT',
    }
    console.log({ noteData })
    const response = await axios.post(`${API_URL}note`, noteData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return response.data || []
  } catch (error) {
    console.log({ error })
    return { status: false, data: [] }
  }
}

// Funciones de API
export const getRoles = async () => {
  const response = await axios.get(`${API_URL}roles`)
  return response.data || [] // Asegura que siempre se devuelva un array
}

export const getRolesSearch = async ({ search = '', page = 1, limit = 10 }) => {
  try {
    const response = await axios.get(`${API_URL}roles/search/`, {
      params: { search, page, limit },
    })

    return {
      data: response.data || [],
      totalPages:
        response.headers['x-total-pages'] || Math.ceil((response.data?.data?.total || 0) / limit),
    }
  } catch (error) {
    console.error('Error fetching users:', error)
    return { users: [], totalPages: 0 }
  }
}

export const getRolesByUser = async id => {
  const response = await axios.get(`${API_URL}roles/byuser/${id}`)
  return response.data || [] // Asegura que siempre se devuelva un array
}

export const createRole = async roleData => {
  const response = await axios.post(`${API_URL}roles`, roleData)
  return response.data || {}
}

export const updateRole = async (id, roleData) => {
  const response = await axios.put(`${API_URL}roles/${id}`, roleData)
  return response.data || {}
}

export const deleteRole = async id => {
  const response = await axios.delete(`${API_URL}roles/${id}`)
  return response.data || {}
}

export const getApps = async () => {
  const response = await axios.get(`${API_URL}apps`)
  return response.data || []
}

export const getAppsByNotes = async userId => {
  const response = await axios.get(`${API_URL}appsbynotes`, {
    params: { userId },
  })
  return response.data || []
}

export const getAppsByFees = async userId => {
  const response = await axios.get(`${API_URL}appsbyfees`, {
    headers: {
      userid: userId, // Aquí se agrega el userId en los headers
    },
  })

  return response.data || []
}

export const createFees = async feesData => {
  const response = await axios.post(`${API_URL}fees_core`, feesData)
  return response.data || {}
}

export const updateFees = async (id, feesData) => {
  const response = await axios.put(`${API_URL}fees_core/${id}`, feesData)
  return response.data || {}
}

export const createApp = async roleData => {
  const response = await axios.post(`${API_URL}apps`, roleData)
  return response.data || {}
}

export const updateApp = async (id, roleData) => {
  const response = await axios.put(`${API_URL}apps/${id}`, roleData)
  return response.data || {}
}

export const deleteApp = async id => {
  const response = await axios.delete(`${API_URL}apps/${id}`)
  return response.data || {}
}

export const getFunctionalities = async () => {
  const response = await axios.get(`${API_URL}functionalities`)
  return response.data || []
}

/*

// Funciones de API
export const searchDefaultSettings = async (filters) => {
  const response = await axios.get(`${API_URL}fees_core/defaultsettings`, { 
    params: filters // Usa los filtros como parámetros de consulta
  });
  return response.data || []; // Asegura que siempre se devuelva un array
};

*/

export const getFunctionalitiesSearch = async ({
  search = '',
  page = 1,
  limit = 10,
  appId = null,
}) => {
  try {
    const response = await axios.get(`${API_URL}functionalities`, {
      params: { search, page, limit, appId }, // Incluye appId en los parámetros
    })
    // console.log('API response:', response.data);
    return {
      users: response.data || [],
      totalPages:
        response.headers['x-total-pages'] || Math.ceil((response.data?.total || 0) / limit),
    }
  } catch (error) {
    console.error('Error fetching users:', error)
    return { users: [], totalPages: 0 }
  }
}

export const createFunctionality = async functionalityData => {
  const response = await axios.post(`${API_URL}functionalities`, functionalityData)
  return response.data || {}
}

export const updateFunctionality = async (id, functionalityData) => {
  const response = await axios.put(`${API_URL}functionalities/${id}`, functionalityData)
  return response.data || {}
}

export const deleteFunctionality = async id => {
  const response = await axios.delete(`${API_URL}functionalities/${id}`)
  return response.data || {}
}

export const getUsersRoles = async () => {
  const response = await axios.get(`${API_URL}userroles`)
  return response.data || []
}

export const createUserRoles = async roleData => {
  const response = await axios.post(`${API_URL}userroles`, roleData)
  return response.data || {}
}

export const updateUserRoles = async (id, roleData) => {
  const response = await axios.put(`${API_URL}userroles/id/${id}`, roleData)
  return response.data || {}
}

export const deleteUserRoles = async id => {
  const response = await axios.delete(`${API_URL}userroles/id/${id}`)
  return response.data || {}
}

export const deleteUserRolesAll = async id => {
  const response = await axios.delete(`${API_URL}userroles/${id}`)
  return response.data || {}
}

export const getRolesForUser = async id => {
  const response = await axios.get(`${API_URL}userroles/${id}`)
  return response.data || []
}

// Nueva función para obtener todos los usuarios sin paginación
export const getAllUsers = async () => {
  try {
    const response = await axios.get(`${API_URL}users/all`)
    // console.log('API response for all users:', response.data);
    const users = Array.isArray(response.data) ? response.data : response.data?.users || []
    return users
  } catch (error) {
    console.error('Error fetching all users:', error)
    return [] // Retorna un array vacío en caso de error
  }
}

export const getUsers = async ({ search = '', page = 1, limit = 10 }) => {
  try {
    const response = await axios.get(`${API_URL}users`, {
      params: { search, page, limit },
    })
    // console.log('API response:', response.data);
    return {
      users: response.data || [],
      totalPages:
        response.headers['x-total-pages'] || Math.ceil((response.data?.total || 0) / limit),
    }
  } catch (error) {
    console.error('Error fetching users:', error)
    return { users: [], totalPages: 0 }
  }
}

// Funciones de API
export const getUser = async id => {
  const response = await axios.get(`${API_URL}users/${id}`)
  return response.data || {}
}

export const createUser = async roleData => {
  const response = await axios.post(`${API_URL}users`, roleData)
  return response.data || {}
}

export const updateUser = async (id, roleData) => {
  const response = await axios.put(`${API_URL}users/${id}`, roleData)
  return response.data || {}
}

export const deleteUser = async id => {
  const response = await axios.delete(`${API_URL}users/${id}`)
  return response.data || {}
}

export const getCodesFor = async type => {
  const response = await axios.get(`${API_URL}codesfor/${type}`)
  return response.data || []
}

// Funciones de API
export const getComplianceProfile = async id => {
  const response = await axios.get(`${API_URL}complianceprofiles`) // no paginar, se obtiene para el selector
  return response.data || {}
}

export const getAllUserAddresses = async ({ userId, search = '', page = 1, limit = 10 }) => {
  try {
    const response = await axios.get(`${API_URL}user_addresses/all/${userId}`, {
      params: { search, page, limit },
    })
    // console.log('API response:', response.data);
    return {
      users: response.data || [],
      totalPages:
        response.headers['x-total-pages'] || Math.ceil((response.data?.total || 0) / limit),
    }
  } catch (error) {
    console.error('Error fetching users:', error)
    return { users: [], totalPages: 0 }
  }
}

/*

// Servicios para gestionar direcciones de usuario
export const getAllUserAddresses = async (userId) => {  // Id de usuario obtener su address

  // console.log("obtiene los address");
  const response = await axios.get(`${API_URL}user_addresses/all/${userId}`);
  return response.data || [];
};

*/

export const getUserAddresses = async userId => {
  //  Id de address
  const response = await axios.get(`${API_URL}user_addresses/${userId}`)
  return response.data || []
}

export const createUserAddress = async (userId, addressData) => {
  const response = await axios.post(`${API_URL}user_addresses`, addressData)
  return response.data || {}
}

export const updateUserAddress = async (addressId, addressData) => {
  const response = await axios.put(`${API_URL}user_addresses/id/${addressId}`, addressData)
  return response.data || {}
}

export const deleteUserAddress = async addressId => {
  const response = await axios.delete(`${API_URL}user_addresses/${addressId}`)
  return response.data || {}
}

export const deleteUserAddressId = async addressId => {
  const response = await axios.delete(`${API_URL}user_addresses/id/${addressId}`)
  return response.data || {}
}

export const getUserDocs = async userId => {
  const response = await axios.get(`${API_URL}user_docs/${userId}`)
  return response.data || []
}

export const createUserDocs = async userDocs => {
  const response = await axios.post(`${API_URL}user_docs`, userDocs)
  return response.data || {}
}

export const updateUserDocsId = async (addressId, userDocs) => {
  const response = await axios.put(`${API_URL}user_docs/id/${addressId}`, userDocs)
  return response.data || {}
}

export const updateUserDocs = async (addressId, userDocs) => {
  const response = await axios.put(`${API_URL}user_docs/${addressId}`, userDocs)
  return response.data || {}
}

export const deleteUserDocs = async addressId => {
  const response = await axios.delete(`${API_URL}user_docs/${addressId}`)
  return response.data || {}
}

// Funciones de API
export const getCountries = async () => {
  const response = await axios.get(`${API_URL}countries`)
  return response.data || []
}

// Apps

export const getAllUserApps = async ({ userId, search = '', page = 1, limit = 10 }) => {
  try {
    const response = await axios.get(`${API_URL}userapps/all/${userId}`, {
      params: { search, page, limit },
    })
    // console.log('API response:', response.data);
    return {
      users: response.data || [],
      totalPages:
        response.headers['x-total-pages'] || Math.ceil((response.data?.total || 0) / limit),
    }
  } catch (error) {
    console.error('Error fetching users:', error)
    return { users: [], totalPages: 0 }
  }
}

export const getUserApp = async userId => {
  //  Id de address
  const response = await axios.get(`${API_URL}userapps/${userId}`)
  return response.data || []
}

export const createUserApp = async (userId, addressData) => {
  const response = await axios.post(`${API_URL}userapps`, addressData)
  return response.data || {}
}

export const updateUserApp = async (addressId, addressData) => {
  const response = await axios.put(`${API_URL}userapps/id/${addressId}`, addressData)
  return response.data || {}
}

/*
export const deleteUserApp = async (addressId) => {
  const response = await axios.delete(`${API_URL}user_addresses/${addressId}`);
  return response.data || {};
};

*/

export const deleteUserAppId = async addressId => {
  const response = await axios.delete(`${API_URL}userapps/id/${addressId}`)
  return response.data || {}
}

// Docs

export const getAllUserDocs = async ({ userId, search = '', page = 1, limit = 10 }) => {
  try {
    const response = await axios.get(`${API_URL}user_docs/all/${userId}`, {
      params: { search, page, limit },
    })
    // console.log('API response:', response.data);
    return {
      users: response.data || [],
      totalPages:
        response.headers['x-total-pages'] || Math.ceil((response.data?.total || 0) / limit),
    }
  } catch (error) {
    console.error('Error fetching users:', error)
    return { users: [], totalPages: 0 }
  }
}

export const getUserDoc = async userId => {
  //  Id de address
  const response = await axios.get(`${API_URL}user_docs/${userId}`)
  return response.data || []
}

export const createUserDoc = async (userId, addressData) => {
  // console.log(addressData,'addressDatazzzzz');

  const response = await axios.post(`${API_URL}user_docs`, addressData)
  return response.data || {}
}

export const updateUserDoc = async (addressId, addressData) => {
  const response = await axios.put(`${API_URL}user_docs/id/${addressId}`, addressData)
  return response.data || {}
}

/*
export const deleteUserDoc = async (addressId) => {
  const response = await axios.delete(`${API_URL}user_docs/${addressId}`);
  return response.data || {};
};

*/

export const deleteUserDocId = async addressId => {
  const response = await axios.delete(`${API_URL}user_docs/id/${addressId}`)
  return response.data || {}
}

// Roles

export const getRolesAllForUser = async ({ userId, search = '', page = 1, limit = 10 }) => {
  try {
    const response = await axios.get(`${API_URL}userroles/all/${userId}`, {
      params: { search, page, limit },
    })
    // console.log('API response:', response.data);
    return {
      users: response.data || [],
      totalPages:
        response.headers['x-total-pages'] || Math.ceil((response.data?.total || 0) / limit),
    }
  } catch (error) {
    console.error('Error fetching users:', error)
    return { users: [], totalPages: 0 }
  }
}

export const getUserRole = async userId => {
  //  Id de address
  const response = await axios.get(`${API_URL}userroles/${userId}`)
  return response.data || []
}

export const getUsersRolesSearch = async ({ search = '', page = 1, limit = 10 }) => {
  try {
    const response = await axios.get(`${API_URL}userroles`, {
      params: { search, page, limit },
    })
    return {
      users: response.data || [],
      totalPages:
        response.headers['x-total-pages'] || Math.ceil((response.data?.total || 0) / limit),
    }
  } catch (error) {
    console.error('Error fetching users:', error)
    return { users: [], totalPages: 0 }
  }
}

export const createUserRole = async (userId, addressData) => {
  const response = await axios.post(`${API_URL}userroles`, addressData)
  return response.data || {}
}

export const updateUserRole = async (addressId, addressData) => {
  const response = await axios.put(`${API_URL}userroles/id/${addressId}`, addressData)
  return response.data || {}
}

/*
export const deleteUserRole = async (addressId) => {
  const response = await axios.delete(`${API_URL}user_addresses/${addressId}`);
  return response.data || {};
};

*/

export const deleteUserRoleId = async addressId => {
  const response = await axios.delete(`${API_URL}userroles/id/${addressId}`)
  return response.data || {}
}

//  Notes

// Funciones de API
export const getNotes = async () => {
  const response = await axios.get(`${API_URL}roles`)
  return response.data || [] // Asegura que siempre se devuelva un array
}

export const getNotesForUser = async id => {
  const response = await axios.get(`${API_URL}userroles/${id}`)
  return response.data || []
}

export const getNotesAllForUser = async ({ userId, search = '', page = 1, limit = 10 }) => {
  try {
    const response = await axios.get(`${API_URL}userroles/all/${userId}`, {
      params: { search, page, limit },
    })
    // console.log('API response:', response.data);
    return {
      users: response.data || [],
      totalPages:
        response.headers['x-total-pages'] || Math.ceil((response.data?.total || 0) / limit),
    }
  } catch (error) {
    console.error('Error fetching users:', error)
    return { users: [], totalPages: 0 }
  }
}

export const getNotesByAppList = async (appId, userId) => {
  try {
    const response = await axios.get(`${API_URL}users/notesbyapp`, {
      headers: {
        app: appId,
        userid: userId,
      },
    }) // Asegúrate de que este cierre sea correcto
    return response.data
  } catch (error) {
    throw new Error('Error fetching notes by app: ' + error.message)
  }
}

/*

export const getNotesByAppList = async (app, userid) => { const response = await axios.get(`${API_URL}users/notesbyapp`);
  return response.data || [];
};

*/

// News

// Funciones de API
export const getNews = async () => {
  const response = await axios.get(`${API_URL}roles`)
  return response.data || [] // Asegura que siempre se devuelva un array
}

export const getNewsForUser = async id => {
  const response = await axios.get(`${API_URL}userroles/${id}`)
  return response.data || []
}

export const getNewsAllForUser = async ({ userId, search = '', page = 1, limit = 10 }) => {
  try {
    const response = await axios.get(`${API_URL}userroles/all/${userId}`, {
      params: { search, page, limit },
    })
    // console.log('API response:', response.data);
    return {
      users: response.data || [],
      totalPages:
        response.headers['x-total-pages'] || Math.ceil((response.data?.total || 0) / limit),
    }
  } catch (error) {
    console.error('Error fetching users:', error)
    return { users: [], totalPages: 0 }
  }
}

export const getNewsByAppList = async (appId, userId) => {
  try {
    const response = await axios.get(`${API_URL}users/newsbyapp`, {
      headers: {
        app: appId,
        userid: userId,
      },
    }) // Asegúrate de que este cierre sea correcto
    return response.data
  } catch (error) {
    throw new Error('Error fetching notes by app: ' + error.message)
  }
}

//  Compliance

// Funciones de API
export const getCompliance = async () => {
  const response = await axios.get(`${API_URL}roles`)
  return response.data || [] // Asegura que siempre se devuelva un array
}

export const getComplianceForUser = async id => {
  const response = await axios.get(`${API_URL}userroles/${id}`)
  return response.data || []
}

export const getComplianceAllForUser = async ({ userId, search = '', page = 1, limit = 10 }) => {
  try {
    const response = await axios.get(`${API_URL}userroles/all/${userId}`, {
      params: { search, page, limit },
    })
    // console.log('API response:', response.data);
    return {
      users: response.data || [],
      totalPages:
        response.headers['x-total-pages'] || Math.ceil((response.data?.total || 0) / limit),
    }
  } catch (error) {
    console.error('Error fetching users:', error)
    return { users: [], totalPages: 0 }
  }
}

// Funciones de API
export const getFees = async () => {
  const response = await axios.get(`${API_URL}roles`)
  return response.data || [] // Asegura que siempre se devuelva un array
}

export const getFeesForUser = async id => {
  const response = await axios.get(`${API_URL}userroles/${id}`)
  return response.data || []
}

export const getFeesAllForUser = async ({ userId, search = '', page = 1, limit = 10 }) => {
  try {
    const response = await axios.get(`${API_URL}userroles/all/${userId}`, {
      params: { search, page, limit },
    })
    // console.log('API response:', response.data);
    return {
      users: response.data || [],
      totalPages:
        response.headers['x-total-pages'] || Math.ceil((response.data?.total || 0) / limit),
    }
  } catch (error) {
    console.error('Error fetching users:', error)
    return { users: [], totalPages: 0 }
  }
}

// Funciones de API
export const getMethods = async () => {
  const response = await axios.get(`${API_URL}methods`)
  return response.data || []
}

// Funciones de API
export const getConcepts = async () => {
  const response = await axios.get(`${API_URL}concepts`)
  return response.data || []
}

export const getFeesByAppList = async userId => {
  try {
    const response = await axios.get(`${API_URL}users/feesbyapp`, {
      headers: {
        app: null,
        userid: userId,
      },
    }) // Asegúrate de que este cierre sea correcto
    return response.data
  } catch (error) {
    throw new Error('Error fetching notes by app: ' + error.message)
  }
}

// Funciones de API
export const getCustomSettings = async () => {
  const response = await axios.get(`${API_URL}fees_core/customsettings`)
  return response.data || [] // Asegura que siempre se devuelva un array
}

// Nueva función para buscar configuraciones personalizadas con filtros
export const searchCustomSettings = async filters => {
  const response = await axios.get(`${API_URL}fees_core/customsettings`, {
    params: filters, // Usa los filtros como parámetros de consulta
  })
  return response.data || [] // Asegura que siempre se devuelva un array
}

export const createCustomSetting = async roleData => {
  const response = await axios.post(`${API_URL}roles`, roleData)
  return response.data || {}
}

export const updateCustomSetting = async (id, roleData) => {
  const response = await axios.put(`${API_URL}roles/${id}`, roleData)
  return response.data || {}
}

export const deleteCustomSetting = async id => {
  const response = await axios.delete(`${API_URL}fees_core/${id}`)
  return response.data || {}
}

// Funciones de API
export const searchDefaultSettings = async filters => {
  const response = await axios.get(`${API_URL}fees_core/defaultsettings`, {
    params: filters, // Usa los filtros como parámetros de consulta
  })
  return response.data || [] // Asegura que siempre se devuelva un array
}

export const createDefaultSetting = async roleData => {
  const response = await axios.post(`${API_URL}roles`, roleData)
  return response.data || {}
}

export const updateDefaultSetting = async (id, roleData) => {
  const response = await axios.put(`${API_URL}roles/${id}`, roleData)
  return response.data || {}
}

export const deleteDefaultSetting = async id => {
  const response = await axios.delete(`${API_URL}roles/${id}`)
  return response.data || {}
}

// Funciones de API
export const searchVerifications = async filters => {
  const response = await axios.get(`${API_URL}verifications`, {
    params: filters, // Usa los filtros como parámetros de consulta
  })
  return response.data || [] // Asegura que siempre se devuelva un array
}

// Funciones de API
export const searchVerificationsByItem = async id => {
  const response = await axios.get(`${API_URL}verifications/byItem/${id}`)
  return response.data || [] // Asegura que siempre se devuelva un array
}

export const createVerification = async roleData => {
  const response = await axios.post(`${API_URL}roles`, roleData)
  return response.data || {}
}

export const updateVerification = async (id, roleData) => {
  const response = await axios.put(`${API_URL}roles/${id}`, roleData)
  return response.data || {}
}

export const deleteVerification = async id => {
  const response = await axios.delete(`${API_URL}roles/${id}`)
  return response.data || {}
}

/*

// Funciones de API
export const getComplianceProfile = async (id) => {
  const response = await axios.get(`${API_URL}complianceprofiles`);
  return response.data || {};
};


// Funciones de API
export const searchProfiles = async (filters) => {
  const response = await axios.get(`${API_URL}profiles`, { 
    params: filters // Usa los filtros como parámetros de consulta
  });
  return response.data || []; // Asegura que siempre se devuelva un array
};

*/
export const searchProfiles = async filters => {
  //  ya obtiene estos datos  en getComplianceProfile
  const response = await axios.get(`${API_URL}profiles`, {
    params: filters, // Usa los filtros como parámetros de consulta
  })
  return response.data || [] // Asegura que siempre se devuelva un array
}

// Funciones de API
export const searchProfilesByItem = async id => {
  const response = await axios.get(`${API_URL}profiles/byItem/${id}`)
  return response.data || [] // Asegura que siempre se devuelva un array
}

export const createProfile = async roleData => {
  const response = await axios.post(`${API_URL}roles`, roleData)
  return response.data || {}
}

export const updateProfile = async (id, roleData) => {
  const response = await axios.put(`${API_URL}roles/${id}`, roleData)
  return response.data || {}
}

export const deleteProfile = async id => {
  const response = await axios.delete(`${API_URL}roles/${id}`)
  return response.data || {}
}

// Rule

export const getRules = async () => {
  const response = await axios.get(`${API_URL}complianceprofilesrules`)
  return response.data || []
}

export const getRuleById = async id => {
  try {
    const response = await axios.get(`${API_URL}complianceprofilesrules/${id}`)
    return response.data || null // Retorna los datos de la regla o null si no se encuentra
  } catch (error) {
    console.error('Error al obtener la regla por ID:', error)
    throw error // Lanza el error para manejarlo en el lugar donde se llama
  }
}

export const createRule = async roleData => {
  const response = await axios.post(`${API_URL}complianceprofilesrules`, roleData)
  return response.data || {}
}

export const updateRule = async (id, roleData) => {
  const response = await axios.put(`${API_URL}complianceprofilesrules/${id}`, roleData)
  return response.data || {}
}

export const deleteRule = async id => {
  const response = await axios.delete(`${API_URL}complianceprofilesrules/${id}`)
  return response.data || {}
}

// Alert

export const getAlerts = async () => {
  const response = await axios.get(`${API_URL}compliancealerts`)
  return response.data || []
}

export const getAlertsByRule = async (id, searchTerm = '', status = '') => {
  const params = new URLSearchParams({
    search: searchTerm,
    status: status.toUpperCase(), // Asegúrate de enviar el estado en mayúsculas
  })
  const response = await axios.get(`${API_URL}compliancealerts/alerts/${id}?${params.toString()}`)
  return response.data || []
}

/*

export const getAlertsByRule = async (id) => {
  const response = await axios.get(`${API_URL}compliancealerts/alerts/${id}`);
  return response.data || [];
};

*/

export const createAlert = async roleData => {
  const response = await axios.post(`${API_URL}compliancealerts`, roleData)
  return response.data || {}
}

export const updateAlert = async (id, roleData) => {
  console.log('URL de actualización:', `${API_URL}compliancealerts/${id}`)
  console.log('Datos enviados:', roleData)
  const response = await axios.put(`${API_URL}compliancealerts/${id}`, roleData)
  return response.data || {}
}

/*

export const updateAlert = async (id, roleData) => {
  const response = await axios.put(`${API_URL}compliancealerts/${id}`, roleData);
  return response.data || {};
};

*/

export const deleteAlert = async id => {
  const response = await axios.delete(`${API_URL}compliancealerts/${id}`)
  return response.data || {}
}

export const getAppsAlerts = async () => {
  const response = await axios.get(`${API_URL}apps/alerts`)
  return response.data || []
}

export const getAllUsersAlerts = async () => {
  try {
    const response = await axios.get(`${API_URL}users/all_alerts`)
    // console.log('API response for all users:', response.data);
    const users = Array.isArray(response.data) ? response.data : response.data?.users || []
    return users
  } catch (error) {
    console.error('Error fetching all users:', error)
    return [] // Retorna un array vacío en caso de error
  }
}

// Obtener usuarios filtrados por application_id
export const getUsersByAppId = async appId => {
  try {
    const response = await axios.get(`${API_URL}apps/${appId}/users`)
    return response.data || []
  } catch (error) {
    console.error(`Error fetching users for app ${appId}:`, error)
    return []
  }
}

// Obtener aplicaciones filtradas por user_id
export const getAppsByUserId = async userId => {
  try {
    const response = await axios.get(`${API_URL}users/${userId}/apps`)
    return response.data || []
  } catch (error) {
    console.error(`Error fetching apps for user ${userId}:`, error)
    return []
  }
}

export const promoverFunctionality = async roleData => {
  try {
    const appDescription = roleData?.data?.app?.description
    const fullData = JSON.stringify(roleData)

    const response = await axios.post(
      `${API_URL}functionalities/promove`,
      {}, // Cuerpo vacío
      {
        headers: {
          app: appDescription,
          data: fullData,
        },
      }
    )

    return response.data || {}
  } catch (error) {
    console.error('Error in promotion:', error)
    if (error.response) {
      // Si hay una respuesta del servidor con error
      throw new Error(error.response.data.message || 'Unknown error occurred')
    }
    throw error
  }
}

export const promoverRole = async roleData => {
  try {
    const response = await axios.post(
      `${API_URL}roles/promove`, // URL del endpoint
      roleData, // Aquí se envía el objeto roleData en el body
      {
        headers: {
          'Content-Type': 'application/json', // Indica que estás enviando JSON
        },
      }
    )

    return response.data || {}
  } catch (error) {
    console.error('Error in promotion:', error)
    if (error.response) {
      // Si hay una respuesta del servidor con error
      throw new Error(error.response.data.message || 'Unknown error occurred')
    }
    throw error
  }
}

/*

export const promoverRole = async (roleData) => {
  try {
    const appDescription = roleData?.data?.app?.description;
    const fullData = JSON.stringify(roleData);


    console.log(fullData);

    const response = await axios.post(
      `${API_URL}roles/promove`,
      {}, // Cuerpo vacío
      {
        headers: {
          app: appDescription,
          data: fullData,
        },
      }
    );


    return response.data || {};
  } catch (error) {
    console.error("Error in promotion:", error);
    if (error.response) {
      // Si hay una respuesta del servidor con error
      throw new Error(error.response.data.message || 'Unknown error occurred');
    }
    throw error;
  }
};

*/

// Scopes

export const getScopes = async () => {
  const response = await axios.get(`${API_URL}scopes`)
  return response.data || [] // Asegura que siempre se devuelva un array
}

export const getScopesSearch = async ({ search = '', page = 1, limit = 10 }) => {
  try {
    const response = await axios.get(`${API_URL}scopes/search/`, {
      params: { search, page, limit },
    })

    return {
      data: response.data || [],
      totalPages:
        response.headers['x-total-pages'] || Math.ceil((response.data?.data?.total || 0) / limit),
    }
  } catch (error) {
    console.error('Error fetching users:', error)
    return { users: [], totalPages: 0 }
  }
}

export const getScopesByUser = async id => {
  const response = await axios.get(`${API_URL}scopes/byuser/${id}`)
  return response.data || [] // Asegura que siempre se devuelva un array
}

export const createUserAppScope = async roleData => {
  try {
    const response = await axios.post(`${API_URL}scopes`, roleData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return response.data || {}
  } catch (error) {
    console.error('Error creating user app scope:', error)
    throw error
  }
}

export const updateScope = async (id, roleData) => {
  const response = await axios.put(`${API_URL}scopes/${id}`, roleData)
  return response.data || {}
}

export const deleteScope = async id => {
  const response = await axios.delete(`${API_URL}scopes/${id}`)
  return response.data || {}
}

// Alerts

export const getAlertsSingle = async () => {
  // getAlerts
  const response = await axios.get(`${API_URL}alerts`)
  return response.data || [] // Asegura que siempre se devuelva un array
}

export const getAlertsTrx = async id => {
  // getAlerts
  const response = await axios.get(`${API_URL}alerts/trx/${id}`)
  return response.data || [] // Asegura que siempre se devuelva un array
}

export const getAlertsSearch = async ({ search = '', page = 1, limit = 10, status = '' }) => {
  try {
    const response = await axios.get(`${API_URL}alerts/search/`, {
      params: { search, page, limit, status }, // Agregar status en los parámetros
    })

    return {
      data: response.data || [],
      totalPages:
        response.headers['x-total-pages'] || Math.ceil((response.data?.data?.total || 0) / limit),
    }
  } catch (error) {
    console.error('Error fetching alerts:', error)
    return { data: [], totalPages: 0 } // Asegúrate de devolver `data` en lugar de `users`
  }
}

export const getAlertsByUser = async id => {
  const response = await axios.get(`${API_URL}alerts/byuser/${id}`)
  return response.data || [] // Asegura que siempre se devuelva un array
}

export const createUserAppAlert = async roleData => {
  try {
    const response = await axios.post(`${API_URL}alerts`, roleData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return response.data || {}
  } catch (error) {
    console.error('Error creating user app alert:', error)
    throw error
  }
}

export const updateAlertSingle = async (id, roleData) => {
  // updateAlert
  const response = await axios.put(`${API_URL}alerts/${id}`, roleData)
  return response.data || {}
}

export const deleteAlertSingle = async id => {
  // deleteAlert
  const response = await axios.delete(`${API_URL}alerts/${id}`)
  return response.data || {}
}

// Checks

// Funciones de API
export const getChecks = async () => {
  const response = await axios.get(`${API_URL}checks`)
  return response.data || [] // Asegura que siempre se devuelva un array
}

export const getCvus = async () => {
  const response = await axios.get(`${API_URL}checks/cvu`)
  return response.data || [] // Asegura que siempre se devuelva un array
}

export const getBanks = async () => {
  const response = await axios.get(`${API_URL}checks/bank`)
  return response.data || [] // Asegura que siempre se devuelva un array
}

export const getChecksSearch = async ({
  search = '',
  page = 1,
  limit = 10,
  status = '',
  cvu = '',
  bank_id = '',
}) => {
  try {
    const response = await axios.get(`${API_URL}checks/search/`, {
      params: { search, page, limit, status, cvu, bank_id }, // Agregar el filtro bank_id
    })

    // Ajustar para extraer la estructura correcta del backend
    return {
      data: response.data.data || [], // Extrae los datos del array `data`
      totalPages: response.data.last_page || 1, // Total de páginas está en `last_page`
    }
  } catch (error) {
    console.error('Error fetching checks:', error)
    return { data: [], totalPages: 0 }
  }
}

export const createCheck = async roleData => {
  const response = await axios.post(`${API_URL}checks`, roleData)
  return response.data || {}
}

export const updateCheck = async (id, roleData) => {
  // console.log("dasdasd",roleData);

  const response = await axios.put(`${API_URL}checks/${id}`, roleData)
  return response.data || {}
}

export const deleteCheck = async id => {
  const response = await axios.delete(`${API_URL}checks/${id}`)
  return response.data || {}
}

// Subtype

/*


export const getSubtypes = async () => {
  const response = await axios.get(`${API_URL}scopes`);
  return response.data || []; // Asegura que siempre se devuelva un array
};



export const getSubtypesSearch = async ({ search = '', page = 1, limit = 10 }) => {
  try {
    const response = await axios.get(`${API_URL}scopes/search/`, {
      params: { search, page, limit }
    });
     

    return {
      data: response.data || [],
      totalPages: response.headers['x-total-pages'] || Math.ceil((response.data?.data?.total || 0) / limit)
    };
  } catch (error) {
    console.error('Error fetching users:', error);
    return { users: [], totalPages: 0 };
  }
};

export const getSubtypesByUser = async (id) => {
  const response = await axios.get(`${API_URL}scopes/byuser/${id}`);
  return response.data || []; // Asegura que siempre se devuelva un array
};


export const deleteSubtype = async (id) => {
  const response = await axios.delete(`${API_URL}scopes/${id}`);
  return response.data || {};
};


*/

// Types

export const getTypes = async () => {
  const response = await axios.get(`${API_URL}elemtypes`)
  return response.data || [] // Asegura que siempre se devuelva un array
}

export const getTypesSearch = async ({ search = '', page = 1, limit = 10, appId = null }) => {
  try {
    const params = { search, page, limit }
    if (appId) params.appId = appId // Agregar appId si está definido

    const response = await axios.get(`${API_URL}elemtypes/`, { params })

    return {
      data: response.data || [],
      totalPages:
        response.headers['x-total-pages'] || Math.ceil((response.data?.total || 0) / limit),
    }
  } catch (error) {
    console.error('Error fetching types:', error)
    return { data: [], totalPages: 0 }
  }
}

export const createType = async roleData => {
  try {
    const response = await axios.post(`${API_URL}elemtypes`, roleData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return response.data || {}
  } catch (error) {
    console.error('Error creating user app scope:', error)
    throw error
  }
}

export const updateType = async (id, roleData) => {
  const response = await axios.put(`${API_URL}elemtypes/${id}`, roleData)
  return response.data || {}
}

export const deleteType = async id => {
  const response = await axios.delete(`${API_URL}elemtypes/${id}`)
  return response.data || {}
}

//  SubTypes

export const getSubtypes = async () => {
  const response = await axios.get(`${API_URL}elemsubtypes`)
  return response.data || [] // Asegura que siempre se devuelva un array
}

export const getSubtypesSearch = async ({
  search = '',
  page = 1,
  limit = 10,
  elemTypeId = null,
}) => {
  try {
    const params = { search, page, limit }
    if (elemTypeId) params.elemTypeId = elemTypeId // Asegurarse de pasar elemTypeId si está definido

    const response = await axios.get(`${API_URL}elemsubtypes/`, { params })

    return {
      data: response.data || [],
      totalPages:
        response.headers['x-total-pages'] || Math.ceil((response.data?.total || 0) / limit),
    }
  } catch (error) {
    console.error('Error fetching subtypes:', error)
    return { data: [], totalPages: 0 }
  }
}

/*

export const getSubtypesSearch = async ({ search = '', page = 1, limit = 10, appId = null }) => {
  try {
    const params = { search, page, limit };
    if (appId) params.appId = appId; // Agregar appId si está definido

    const response = await axios.get(`${API_URL}elemsubtypes/`, { params });

    return {
      data: response.data || [],
      totalPages: response.headers['x-total-pages'] || Math.ceil((response.data?.total || 0) / limit),
    };
  } catch (error) {
    console.error('Error fetching types:', error);
    return { data: [], totalPages: 0 };
  }
};

*/

export const createSubtype = async roleData => {
  try {
    const response = await axios.post(`${API_URL}elemsubtypes`, roleData, {
      headers: {
        'Content-Subtype': 'application/json',
      },
    })
    return response.data || {}
  } catch (error) {
    console.error('Error creating user app scope:', error)
    throw error
  }
}

export const updateSubtype = async (id, roleData) => {
  const response = await axios.put(`${API_URL}elemsubtypes/${id}`, roleData)
  return response.data || {}
}

export const deleteSubtype = async id => {
  const response = await axios.delete(`${API_URL}elemsubtypes/${id}`)
  return response.data || {}
}

export const getAllTypes = async ({ search = '', appId = null }) => {
  try {
    const params = { search, no_paginate: true } // Agregar no_paginate por defecto
    if (appId) params.appId = appId // Agregar appId si está definido

    const response = await axios.get(`${API_URL}elemtypes/`, { params })

    return response.data || [] // Solo retorna los datos sin paginación
  } catch (error) {
    console.error('Error fetching all types:', error)
    return []
  }
}

/*




export const getScopes = async () => {
  const response = await axios.get(`${API_URL}scopes`);
  return response.data || []; // Asegura que siempre se devuelva un array
};



export const getScopesSearch = async ({ search = '', page = 1, limit = 10 }) => {
  try {
    const response = await axios.get(`${API_URL}scopes/search/`, {
      params: { search, page, limit }
    });
     

    return {
      data: response.data || [],
      totalPages: response.headers['x-total-pages'] || Math.ceil((response.data?.data?.total || 0) / limit)
    };
  } catch (error) {
    console.error('Error fetching users:', error);
    return { users: [], totalPages: 0 };
  }
};

export const getScopesByUser = async (id) => {
  const response = await axios.get(`${API_URL}scopes/byuser/${id}`);
  return response.data || []; // Asegura que siempre se devuelva un array
};



export const createUserAppScope = async (roleData) => {
  try {
    const response = await axios.post(`${API_URL}scopes`, roleData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data || {};
  } catch (error) {
    console.error('Error creating user app scope:', error);
    throw error;
  }
};


export const updateScope = async (id, roleData) => {
  const response = await axios.put(`${API_URL}scopes/${id}`, roleData);
  return response.data || {};
};

export const deleteScope = async (id) => {
  const response = await axios.delete(`${API_URL}scopes/${id}`);
  return response.data || {};
};










export const getTypesByUser = async (id) => {
  const response = await axios.get(`${API_URL}elemtypes/byuser/${id}`);
  return response.data || []; // Asegura que siempre se devuelva un array
};

*/
