export function formatMoney(amount) {
  return new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount)
}

export function formatToDecimal(number) {
  const numberStr = number.toString()

  if (numberStr.length <= 2) {
    return `0.${numberStr.padStart(2, '0')}`
  }
  const integerPart = numberStr.slice(0, -2)
  const decimalPart = numberStr.slice(-2)

  return `${integerPart}.${decimalPart}`
}
