import { Box, Container, Typography, Grid, Divider, Stack, Slider, Button } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getAlertById } from '../../../Services/api'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'

const InfoItem = ({ label, value }) => (
  <Grid container spacing={1} alignItems="center" sx={{ my: 0.5 }}>
    <Grid item xs={4}>
      <Typography variant="body1">{label}</Typography>
    </Grid>
    <Grid item xs={8}>
      <Typography variant="body1" fontWeight="bold" textAlign="left">
        {value}
      </Typography>
    </Grid>
  </Grid>
)

const UserInfo = () => {
  const { id } = useParams()
  const [changeLevel, setChangeLevel] = useState(null)
  const { data, error, isLoading } = useQuery({
    queryKey: ['alert', id],
    queryFn: () => getAlertById(id),
  })
  const {
    compliance_profile_rule_id,
    created_at,
    rule_key,
    rule_value,
    status,
    user_app_id,
    user_value,
    level,
    profile_rule: { type } = {},
    app_user: { user: { email } = {} } = {},
  } = data?.data || {}

  useEffect(() => {
    if (level !== undefined && changeLevel === null) {
      setChangeLevel(level)
    }
  }, [level, changeLevel])

  if (isLoading) return <Typography>Cargando...</Typography>
  if (error) return <Typography>Error al cargar los datos.</Typography>

  return (
    <Container>
      <Box py={2} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
        <Stack direction={'row'} spacing={2}>
          <Typography variant="body1">ID:</Typography>
          <Typography variant="body1" fontWeight={'bold'}>
            {id}
          </Typography>
        </Stack>
      </Box>
      <Grid container spacing={2} justifyContent="start" alignItems="center">
        <Grid item xs={12} md={6}>
          <InfoItem label="Email" value={email} />
        </Grid>
        <Grid item xs={12} md={6}>
          <InfoItem label="Compliance Profile" value={compliance_profile_rule_id} />
        </Grid>

        <Grid item xs={12}>
          <Divider sx={{ mt: 2, mb: 2 }} />
        </Grid>

        <Grid item xs={12} md={6}>
          <InfoItem label="Rule Key" value={rule_key} />
        </Grid>
        <Grid item xs={12} md={6}>
          <InfoItem label="Type" value={type} />
        </Grid>

        <Grid item xs={12} md={6}>
          <InfoItem label="Values" value={`Rule: ${rule_value} --- User: ${user_value}`} />
        </Grid>

        <Grid item xs={12}>
          <Divider sx={{ mt: 2, mb: 2 }} />
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4}>
              <Typography variant="body1">Level</Typography>
            </Grid>
            <Grid item xs={5}>
              <Slider
                aria-label="Level"
                value={changeLevel}
                step={1}
                min={0}
                max={100}
                valueLabelDisplay="on"
                onChange={(e, value) => setChangeLevel(value)}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                fullWidth
                variant="contained"
                disabled={changeLevel === level}
                color="primary"
                onClick={() => {}}
              >
                Change
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={'none'} md={6} />
        <Grid item xs={12} md={6}>
          <InfoItem label="Status" value={status} />
        </Grid>
        <Grid item xs={'none'} md={6} />

        <Grid item xs={12} md={6}>
          <InfoItem
            label="Created At"
            value={created_at ? format(new Date(created_at), 'dd/MM/yyyy HH:mm') : '---'}
          />
        </Grid>
        <Grid item xs={'none'} md={6} />
      </Grid>
    </Container>
  )
}

export default UserInfo
