import React from 'react'
import { Navigate } from 'react-router-dom'
import { useUser } from './../UserContext'
import LoadingComponent from '../Components/LoadingComponent'

export const withAuth = WrappedComponent => {
  const AuthComponent = props => {
    const { user, loading } = useUser()

    if (loading) {
      return <LoadingComponent />
    }

    if (!user) {
      return <Navigate to="/login" />
    }

    return <WrappedComponent {...props} user={user} />
  }

  return AuthComponent
}

export default withAuth
